html, body {
	font: 14px/1.21 'Helvetica Neue', arial, sans-serif;
	font-weight: 400;
	margin: 0px;
	padding: 0px;
	background-color: #272727;
	color: #fff;
	--brand-colorcode: 251,61,63;
	--brand-color: rgb(var(--brand-colorcode));
}

.topbar {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	flex-grow: 1;
	padding: 5px 15px;
	background-color: var(--brand-color);
	justify-content: space-between;
	position: sticky;
	top: 0px;
	z-index: 100;
}

.logo {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.links {
	display: flex;
	gap: 20px;
	cursor: pointer;
}

.dropdown {
	position: relative;
	padding: 5px 10px;
	cursor: pointer;
	flex-basis: 70px;
	border-radius: 10px;
}

.currentlanguage:after {
	content: '▾';
	margin-left: 10px;
}

.drowdownlist {
	position: absolute;
	right: 0px;
	top: 100%;
	width: 100px;
	background-color: #313131;
	box-shadow: 0px 0px 5px rgba(0,0,0,0.15);
	border-radius: 5px;
	padding: 2px;
	z-index: 100;
}

.drowdownlist .language {
	padding: 10px 15px;
	cursor: pointer;
	border-radius: 3px;
}

.drowdownlist .language:hover {
	background-color: #383838;
}

.carousal_section {
	padding: 20px;
}

.glide {
	position: relative;
}

ul.glide__slides {
	padding: 10px 0px !important;
}

.glide__slide {
	width: 100px;
	height: 100px;
	color: #000;
	border-radius: 5px;
	transform: scale(1);
	transform-origin: center center;
	transition: transform 0.3s ease;
}

.glide__slide:hover {
	transform: scale(1.05);
	z-index: 100;
}

.slider__image {
	object-fit: cover;
	width: 100%;
	height: 100%;
	max-width: 100%;
	max-height: 100%;
}
.glide__arrow {
	position: absolute;
	top: 50%;
}
.glide__arrow {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: rgb(var(--brand-colorcode));
	outline: none;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 36px;
	padding: 0px;
	font-weight: bold;
	cursor: pointer;
	position: absolute;
}
.glide__arrow svg {
	stroke: #fff;
	fill: #fff;
}
.glide__arrow.glide__arrow--left {
	left: 10px;
}
.glide__arrow.glide__arrow--right {
	right: 10px;
}

.footer {
	padding: 10px;
	margin-bottom: 20px;
	background: inherit;
	display: flex;
	align-items: center;
	justify-content: center;
}
.contactmail {
	color: #fff;
}
.contactmail:hover {
	color: rgb(var(--brand-colorcode));
}